







































































































































































































































































import { validationMixin } from "vuelidate";
import { mixins } from "vue-class-component";
import ErrorMessageHandlerMixin from "@/misc/ErrorMessageHandler.mixins";
import { Component, Prop, Watch } from "vue-property-decorator";
import User from "@/models/User.model";
import { Gender } from "@/enum/UserTitle.enum";
import { UserDegree } from "@/enum/UserDegree";
import { UserType } from "@/enum/UserType.enum";
import {
  email,
  helpers,
  integer,
  maxLength,
  minLength,
  minValue,
  required,
  requiredIf
} from "vuelidate/lib/validators";

// Regex für deutsche Kfz-Kennzeichen
const licensePlateRegex = /\b([A-ZÄÖÜ]{1,3})-([A-Z]{1,2})-([1-9][0-9]{0,3})([EH]?)\b/;

// Custom validator using the regex
const isLicensePlate = helpers.regex("isLicensePlate", licensePlateRegex);

@Component({
  mixins: [validationMixin],
  validations: {
    user: {
      gender: {
        required
      },
      firstName: {
        required: requiredIf((user: User, p) => {
          return user.type === UserType.CUSTOMER;
        }),
        maxLength: maxLength(50)
      },
      lastName: {
        required: requiredIf((user: User, p) => {
          return user.type === UserType.CUSTOMER;
        }),
        maxLength: maxLength(50)
      },
      email: {
        required,
        email
      },
      phone: {
        required,
        minValue: minValue(0),
        integer
      },
      address: {
        city: {
          required
        },
        houseNr: {
          required,
          integer,
          maxLength: maxLength(10)
        },
        street: {
          required,
          maxLength: maxLength(50)
        },
        zip: {
          required,
          minLength: minLength(5),
          maxLength: maxLength(5),
          integer
        }
      }
    },
    licensePlate: {
      isLicensePlate
    }
  }
})
export default class RegisterMainComponent extends mixins(ErrorMessageHandlerMixin) {
  /**
   * User object
   */
  @Prop({ required: true })
  public user!: User;

  /**
   * Did the User accept the Terms of Service
   * @private
   */
  @Prop({ default: false })
  private termsOfService!: boolean;

  /**
   * Did the User accept the Privacy Policy
   * @private
   */
  @Prop({ default: false })
  private agb!: boolean;

  /**
   * Local Instances of Checkbox Values
   * @private
   */
  private localTermsOfService: boolean = false;
  private localAgb: boolean = false;

  /**
   * License Plate
   * @private
   */
  private licensePlate: string = "";

  /**
   * Url to the Privacy Policy PDF for the License Plates
   * @private
   */
  private licensePlatePrivacyPdfUrl = process.env.VUE_APP_LICENSE_PLATE_PRIVACY_PDF || "";

  public mounted() {
    this.localTermsOfService = this.termsOfService;
    this.localAgb = this.agb;
  }

  @Watch("termsOfService")
  @Watch("agb")
  private listenTermsOfService() {
    this.localTermsOfService = this.termsOfService;
    this.localAgb = this.agb;
  }

  /**
   * different gender options
   * @private
   */
  private gOptions = [Gender.MALE, Gender.FEMALE, Gender.DIVERSE, Gender.NOT_SET];

  /**
   * Loading State bool
   * @private
   */
  private isLoading: boolean = false;

  private onTermsAndConditionsChanged(val: boolean) {
    this.$emit("termsAndConditionsChanged", val);
  }

  private onLicensePlateChanged(val: string) {
    this.$emit("licensePlateChanged", val);
  }

  private onAgbChanged(val: boolean) {
    this.$emit("agbChanged", val);
  }

  private get checkedTermsOfService() {
    return this.termsOfService && this.agb;
  }

  /**er
   * different degreeOptions
   * @private
   */
  private dOptions = [
    UserDegree.PROF,
    UserDegree.PHD,
    UserDegree.DR,
    UserDegree.BA,
    UserDegree.MBA,
    UserDegree.PROFDR,
    UserDegree.NONE
  ];

  private get isValid() {
    return !this.$v.$invalid;
  }

  /**
   * returns translated genders based on the gender enum
   * @private
   */
  private get genderOptions() {
    return this.gOptions.map((n: Gender) => this.$t("GENERAL.GENDER." + n));
  }

  private onRegister() {
    this.$v.$touch();

    if (this.$v.$invalid) {
      return;
    }

    this.$emit("register", this.user);
  }

  private switchToCompany() {
    this.$emit("typeChanged", UserType.COMPANY);
  }

  private switchToCustomer() {
    this.$emit("typeChanged", UserType.CUSTOMER);
  }

  private get isCustomer() {
    return this.user.type === UserType.CUSTOMER;
  }
}
